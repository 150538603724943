import { useEffect, useState } from 'react';
import NewsCard from '../templates/NewsCard';
import { apiService } from '../services/apiService';
import Loader from '../templates/Loader';
import { Modal } from 'react-bootstrap';
import UpgradeModal from '../templates/UpgradeModal';
import { authenticationService } from '../services/authenticationService';

export default function NewsGrid({ props, onError }) {

    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    useEffect(() => {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/news/list')
            .then((resp) => {
                setData(resp.data);
                setLoadingData(false);
            },
            error => {
                if ('401' === error.message) {
                    authenticationService.logout();
                } else {
                    onError(error.message + ' error');
                }   
            });
    }, [onError]);


    return (
        <>
            {loadingData ? (
                <Loader />
            ) : (
                <>
                    { 0 < data.length && 
                        <>
                            
                            <div className="d-flex overflow-scroll fixed-1-and-5-row  overflow-md-visible d-grid-md grid-gap-3 grid-md-2-cols pb-2 pb-md-0 position-relative">
                                <div className='column-span-2 d-none d-md-flex justify-content-end mb-7'>
                                    <div className="text-decoration-none cursor-pointer text-fluoro-green-500 small" onClick={handleShowModal}>More news<i className="ms-3 fa-solid fa-arrow-right small"></i></div>
                                </div>
                                {
                                    data.slice(1,7).map(function (object, i) {
                                        return (
                                            <div className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 w-md-100 bg-dark-mode-300 rounded-2 position-relative text-decoration-none p-3" key={i}>
                                                <NewsCard data={object} />
                                            </div>
                                        );
                                    })
                                }
                                <div className="btn d-none d-md-flex justify-content-center column-span-2 bg-fluoro-green-500  fw-semi-bold rounded-2 position-relative text-decoration-none py-2 small" onClick={handleShowModal}>More from the news <i className="ms-2 fa-solid fa-arrow-right small"></i></div>
                            </div>
                            <div className="btn d-flex mt-2 d-md-none justify-content-center column-span-2 bg-fluoro-green-500  fw-semi-bold rounded-2 position-relative text-decoration-none py-2 small" onClick={handleShowModal}>More from the news <i className="ms-2 fa-solid fa-arrow-right small"></i></div>
                        </>
                    }
                </>
            )}
            <Modal size="lg" centered show={showModal} onHide={handleCloseModal}>
                <Modal.Body className='bg-dark-mode-50 rounded-2 position-relative'>
                    <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-900 disc-md-45 btn-close mt-n3 me-md-n35 me-n3"
                        onClick={handleCloseModal} ></button>
                    <div className='p-md-3 mb-0'>
                        <UpgradeModal />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}