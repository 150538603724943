import React from 'react';

const UpgradeModal = () => {
    return (
        <div className="pt-md-4 pt-2 px-md-4 px-2">
            <h3 className="mb-3">Upgrade today!</h3>
            <p className="fw-normal">Want access to all the platform features, contact us to discuss how we can help you get the most from SportsKinetic.</p>
            <div className="row row-cols-lg-2 row-cols-md-1 g-4">
                <div className="col">
                    <div className="h-100">
                        <h5 className="fw-extra-bold mb-md-3 mb-2">Contact us</h5>
                        <p className="fw-normal mb-md-3 mb-2">Contact our sales team today.</p>
                        <div className="d-flex mb-2">
                            <div className="fw-semi-bold me-5">Tel</div>
                           <a href="tel:++440201234567">+44 (0) 20 123 4567</a> 
                        </div>
                        <div className="d-flex mb-md-4 mb-2half">
                            <div className="fw-semi-bold me-3">Email</div><a href="mailto:upgrade@sportskinetic.ai">upgrade@sportskinetic.ai</a>
                        </div>
                        <div className="btn btn-lg bg-fluoro-green-500 w-100 text-decoration-none small flex-0 fw-semi-bold rounded-2">Contact us</div>
                    </div>
                </div>
                <div className="col">
                    <div className="h-100">
                        <h5 className="fw-extra-bold mb-md-3 mb-2">Register for our test program</h5>
                        <p className="fw-normal mb-4">Be the earliest to hear about our new features and be first in line to test and give feedback about the results you receive.</p>
                        <div className="btn btn-lg bg-zero-dark w-100 text-decoration-none small flex-0 fw-semi-bold text-white rounded-2">Register your interest</div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default UpgradeModal;
