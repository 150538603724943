import HelperFunctions from '../helpers/HelperFunctions';
export default function FeaturedNewsCard({ props, data = {} }) {


    const { dateFormatter, stringTruncate } = HelperFunctions();
    const { type, text, source_name, source_url, news_date, media } = { ...data };

    return (
        <div className="d-grid-md h-100  fixed-1-row">
            <div className="mb-3 mb-md-0">
                <div className="d-flex justify-content-between">
                    <p className="large d-md-block d-none fw-semi-bold mb-2">News feed</p>
                    <p className="small d-md-none d-block fw-semi-bold mb-2">News feed</p>
                    <a href="/" className="d-md-none text-decoration-none small flex-0"><i className="ms-3 fa-light fa-arrow-right small"></i></a>
                </div>
                <p className="mb-0">Keeping you up to date with all the football news and updates you may have missed.</p>
            </div>
            <a href={source_url} target='_blank' rel='noreferrer' className="d-flex h-100  bg-dark-mode-300 rounded-2  position-relative b text-decoration-none p-3 w-100">
                <div className="d-flex flex-row h-100 w-100 justify-content-between">
                    <div className="d-flex flex-column h-100 justify-content-between">
                        <div>
                            <div className="d-flex align-items-center justify-content-start mb-3">
                                <div className="d-flex align-items-center justify-content-start me-3">
                                    <img className="object-fit-cover w-px-68 h-px-68 rounded-2 flex-none d-md-block d-none" src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' + source_url + '&size=128'} alt="icon" />
                                    <img className="object-fit-cover w-px-48 h-px-48 rounded-2 flex-none d-md-none d-block" src={'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' + source_url + '&size=64'} alt="icon" />
                                </div>
                                <div>
                                    <p className="mb-0 small">{source_name}</p>
                                    <p className=" text-neutral-gray-700 mb-0 small">{type !== 'api' ? type : ''}</p>
                                </div>
                            </div>
                            <img src={media} alt="NewsImage" className='w-100 mb-3 rounded-2' />

                            <p className=" text-neutral-gray-500 large d-md-block d-none mb-3">{stringTruncate(text, 200)}</p>
                            <p className=" text-neutral-gray-500 small d-md-none d-block mb-3">{stringTruncate(text, 200)}</p>
                            {false && <p className=" text-neutral-gray-500 d-md-block d-none mb-3">According to information released yesterday in <strong>Argentina, Liverpool and Bayern Munich</strong>, clubs previously associated with the Porto player, are keeping an eye on him for this summer market.</p>}
                        </div>

                        <div className="d-flex justify-content-end align-items-center">
                            <div className="small text-neutral-gray-50">{news_date ? dateFormatter(news_date) : "25th July 2024"}</div>
                        </div>
                    </div>

                </div>
            </a>
        </div>
    );
}
