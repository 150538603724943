import { Route, Routes } from 'react-router-dom';
import React from "react";
import './scss/app.scss';
import './scss/fontawesome6.5.2/scss/fontawesome.scss';
import './scss/fontawesome6.5.2/scss/solid.scss';
import './scss/fontawesome6.5.2/scss/light.scss';
import './scss/fontawesome6.5.2/scss/regular.scss'

import Home from './pages/Home';
import PlayerDetail from './pages/PlayerDetail';
import PlayerNews from './pages/PlayerNews';
import Login from './pages/Login';
import PrivateRoute from './hooks/PrivateRoute';
import PlayerListing from './pages/PlayerListing';
import PerfectPlayer from './pages/PerfectPlayer';
import Header from './components/Header';

function App() {
  return (
    <>
      <div className="container">
        <Header/>
        <main className='py-4 py-md-5'>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<PrivateRoute element={Home} />} />
            <Route path="/playernews" element={<PrivateRoute element={PlayerNews} />} />
            <Route path="/playerdetail/:uuid" element={<PrivateRoute element={PlayerDetail} />} />
            <Route path="/playerlisting" element={<PrivateRoute element={PlayerListing} />} />
            <Route path="/perfectplayer" element={<PrivateRoute element={PerfectPlayer} />} />
          </Routes>
        </main>

        <footer>
        </footer>
      </div>
    </>
  );
}

export default App;
