import AssociatedTeamsCard from '../templates/AssociatedTeamsCard';
import InsightCard from '../templates/InsightCard';
import PlayerTransfer from './PlayerTransfer';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';
import HelperFunctions from '../helpers/HelperFunctions';
import UpgradeModal from '../templates/UpgradeModal';

export default function PlayerInfo({ props, playerinfo, insights }) {

    const [showModal, setShowModal] = useState(false);
    const [showPlayerTransferModal, setShowPlayerTransferModal] = useState(false);
    const [showPlayerTransferOffCanvas, setShowPlayerTransferOffCanvas] = useState(false);

    const handleClosePlayerTransferModal = () => setShowPlayerTransferModal(false);
    const handleShowPlayerTransferModal = () => setShowPlayerTransferModal(true);

    const handleClosePlayerTransferOffCanvas = () => setShowPlayerTransferOffCanvas(false);
    const handleShowPlayerTransferOffCanvas = () => setShowPlayerTransferOffCanvas(true);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const splitName = (name = '') => {
        const [firstName, ...lastName] = name.split(' ').filter(Boolean);
        return {
          firstName: firstName,
          lastName: lastName.join(' ')
        }
      }

    const { first_name, last_name, date_of_birth, team, basic_player_stats, image, positions = [] } = playerinfo;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [playerinfo]);

    console.log(splitName(first_name));

    const { ageFormatter } = HelperFunctions();
    const age = date_of_birth ? ageFormatter(date_of_birth) : '';
    return (
        <div>
            <div className='row row-cols-md-2 row-cols-1 gx-2 gy-4'>
                <div className='col d-flex align-items-center'>
                    <div className='w-100'>
                        <div className='d-flex flex-row align-items-center d-flex justify-content-md-start justify-content-between'>
                            <div className='d-flex align-items-center'>
                                {image ?
                                    <img className="object-fit-cover disc-55 disc-md-9 rounded-circle bg-white mb-0 mb-md-3 me-2 me-md-0" src={image} alt={first_name} />
                                    :
                                    <svg className='disc-55 disc-md-9 mb-0 mb-md-3 me-2 me-md-0' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 105 104">
                                        <g clipPath="url(#a)">
                                            <rect width="104" height="104" x=".8" fill="#3A3C43" rx="52" />
                                            <g fill="#80909D" clipPath="url(#b)">
                                                <path d="M61.83 68.84H44.17A30.15 30.15 0 0 0 14 98.97c0 3.33 2.7 6.03 6.03 6.03h65.94A6.02 6.02 0 0 0 92 98.97a30.15 30.15 0 0 0-30.17-30.13Z" />
                                                <path d="M75.29 38.25A22.27 22.27 0 0 1 53 60.5a22.27 22.27 0 0 1-22.29-22.25A22.27 22.27 0 0 1 53 16c12.3 0 22.29 9.96 22.29 22.25Z" opacity=".4" />
                                            </g>
                                        </g>
                                        <defs>
                                            <clipPath id="a">
                                                <rect width="104" height="104" x=".8" fill="#fff" rx="52" />
                                            </clipPath>
                                            <clipPath id="b">
                                                <path fill="#fff" d="M14 16h78v89H14z" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                }
                                <div className='d-flex flex-column align-items-start align-items-md-center px-md-4 ps-2'>
                                    <p className="d-block mb-0 text-left w-100 fw-normal">{first_name} <strong className='fw-semi-bold'>{last_name}</strong></p>
                                    <p className="d-md-block d-none mb-0 small text-left w-100">{age}&nbsp;&middot;&nbsp;{positions.map(position => position.name).join('/')}</p>
                                    <p className="d-md-none d-block mb-0 extra-small text-left w-100">{age}&nbsp;&middot;&nbsp;{positions.map(position => position.name).join('/')}</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <i className="fa-light fa-share-nodes me-2 text-fio-error-100"></i>
                                <i className="fa-regular fa-grid-2-plus bg-secure-blue-500 p-2half rounded-circle cursor-pointer" onClick={handleShowModal}></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-md-flex align-items-center justify-content-end gap-3 order-md-0 order-3 col'>
                    <div className='w-100 w-px-md-360 mb-2'>
                        <AssociatedTeamsCard team={team} basic_player_stats={basic_player_stats}/>
                    </div>
                    <div className="bg-dark-mode-300 rounded-2 d-flex align-items-center justify-content-center">
                        <div className='d-md-flex d-none' onClick={handleShowPlayerTransferModal}>
                            <i className="fa-regular fa-clock-rotate-left py-4 px-5 cursor-pointer"></i>
                        </div>
                        <p className='cursor-pointer mb-0 d-md-none small py-2 px-3 d-flex'
                            onClick={handleShowPlayerTransferOffCanvas}>View transfer history</p>
                    </div>
                    <div className='bg-dark-mode-300 rounded-2 d-md-none d-flex align-items-center justify-content-center'>
                    </div>
                </div>
                {insights?.map(function (object, i) {
                    return (
                        <div key={i} className={`col ${i === 1 ? 'd-none d-md-block' : ''}`}>
                            <InsightCard index={i} insights={object} />
                        </div>
                    );
                })}
            </div>
            <Modal size="lg" show={showPlayerTransferModal} onHide={handleClosePlayerTransferModal}>
                <Modal.Body className=' bg-dark-mode-300 rounded-2 mt-3 mb-3 py-2 px-4 position-relative'>
                    <button type="button" className="d-none d-md-flex position-absolute z-index-1 end-0 top-0 disc-4 bg-zero-dark-300  disc-md-45 btn-close mt-n5 me-md-n3 me-n3"
                        onClick={handleClosePlayerTransferModal} ></button>
                    <div className='overflow-y-auto min-vh-25 max-vh-75'>
                        <div className='d-block d-md-none my-4  cursor-pointer'>
                            <div
                                className="small flex-0 "
                                onClick={handleClosePlayerTransferModal}
                            ><i className="me-3 fa-light fa-arrow-left small"></i> Back to player information</div>
                        </div>
                        <PlayerTransfer isInModal={true} transferInfo={playerinfo} />
                    </div>
                </Modal.Body>
            </Modal>
            <Offcanvas className="min-vh-100 max-vh-100 min-vw-100 max-vw-100 bg-dark-mode-800 rounded-2" show={showPlayerTransferOffCanvas} onHide={handleClosePlayerTransferOffCanvas} placement="end">
                <Offcanvas.Body className=' mt-3 mb-3 position-relative'>
                    <button type="button" className="d-none d-md-flex position-absolute z-index-1 end-0 top-0 disc-4 bg-zero-dark-300  disc-md-45 btn-close mt-n5 me-md-n3 me-n3"
                        onClick={handleClosePlayerTransferOffCanvas} ></button>
                    <div className='d-block d-md-none my-4'>
                        <div
                            className="small flex-0 cursor-pointer "
                            onClick={handleClosePlayerTransferOffCanvas}
                        ><i className="me-3 fa-light fa-arrow-left small"></i> Back to player information</div>
                    </div>
                    <PlayerTransfer isInModal={false} transferInfo={playerinfo} />
                </Offcanvas.Body>
            </Offcanvas>
            <Modal size="lg" centered show={showModal} onHide={handleCloseModal}>
                <Modal.Body className='bg-dark-mode-50 rounded-2 position-relative'>
                    <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-900 disc-md-45 btn-close mt-n3 me-md-n35 me-n3"
                        onClick={handleCloseModal} ></button>
                    <div className='p-md-3 mb-0'>
                        <UpgradeModal />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
