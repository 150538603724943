import React, { useState } from 'react'
import FilterResults from '../components/FilterResults'
import Modal from '../templates/Modal';
import Filters from '../components/Filters';
import Breadcrumb from '../templates/Breadcrumb';
const PlayerListing = () => {
    const [modalMessage, setModalMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const breadcrumbItems = [
        { label: 'Home', to: '/' },
        { label: 'Player listing' }
    ];
    return (
        <div>
            <Breadcrumb items={breadcrumbItems} />
            {showModal &&
                <Modal message={modalMessage} onClose={() => setShowModal(false)} />
            }
            <div className='d-md-flex '>
                <div className="flex-grow-0 d-md-block d-none w-px-md-360 min-w-px-md-360 me-4 mt-1">
                    <Filters />
                </div>
                <div className="flex-grow-1">
                    <FilterResults onError={(message) => { setShowModal(true); setModalMessage(message) }} />
                </div>
            </div>
        </div>
    )
}

export default PlayerListing
