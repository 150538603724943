import React, { useEffect } from 'react';
import homepage from "../images/homepage.png";
import mobileHomapgae from "../images/mobileHomapgae.png";
// import Feature from '../components/loggedOut/Feature';
import Records from '../components/loggedOut/Records';
import OurTeam from '../components/loggedOut/OurTeam';
import GetInTouch from '../components/loggedOut/GetInTouch';

const LoggedOutHome = () => {

    useEffect(() => {
        const parallaxImage = document.querySelector('.parallax');

        if (parallaxImage) {
            const transitionTimingFunction = 'cubic-bezier(0, 0, 0, 0.8)';
            parallaxImage.style.transition = 'transform 0.5s ' + transitionTimingFunction;

            const handleScroll = () => {
                const scrollPosition = window.scrollY;
                parallaxImage.style.transform = 'translateY(' + scrollPosition * -0.2 + 'px)';
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return (
        <div>
            <div>
                <div className="position-relative w-100 center-content pb-7" id="section-heroTitle" name="section-heroTitle">
                    <div className="center-content flex-column w-100">
                        <div className="position-relative  w-100  pt-4 pt-md-7">
                            <div className="container-fluid center-content flex-column  pb-7 z-index-1">
                                <div className="d-flex d-md-none flex-column align-items-center mb-3">
                                    <p className="display-5 d-table fw-extra-bold  bg-zero-dark-500 text-white text-center mb-0 pt-2 pb-1 px-3 ">Master the</p>
                                    <p className="display-5 d-table fw-extra-bold  bg-zero-dark-500 text-white text-center mb-0 pt-2 pb-1 px-3">Game with AI-Driven</p>
                                    <p className="display-5 d-table fw-extra-bold  bg-zero-dark-500 text-white  text-center mb-0 pt-1 pb-2 px-3"> Excellence.</p>
                                </div>
                                <div className="d-none d-md-flex flex-column align-items-center mb-3 z-index-1">
                                    <p className="display-5 d-table fw-extra-bold  bg-zero-dark-500 text-white text-center mb-0 pt-2 pb-1 px-3">Master the Game with AI-Driven Excellence.</p>
                                </div>

                                <div className="d-flex flex-column align-items-center z-index-1">
                                    <p className=" max-w-f-600 mb-0 large text-center mb-5 mb-md-6 d-flex flex-column align-items-center">Experts in Data and Analysis for scouting and player performance.</p>
                                </div>
                                <div className="center-content w-100 z-index-1">
                                    <a href="mailto:contact@sportskinetic.ai" className="btn btn-lg rounded-2 d-none d-md-block bg-fluoro-green-500  text-decoration-none fw-semi-bold me-1">Speak to our team</a>
                                    <a href="mailto:contact@sportskinetic.ai" className="btn btn-md rounded-2 d-md-none small  bg-fluoro-green-500  text-decoration-none fw-semi-bold me-1">Speak to our team</a>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-hero border border-zero-dark-50 rounded-3 h-px-680 overflow-hidden d-none d-md-block">
                            <img src={homepage} alt="hero-banner" className="mw-100 d-none d-md-block parallax" />
                        </div>
                        <div className="d-block d-md-none shadow-hero border border-zero-dark-50 rounded-3 h-px-680 overflow-hidden">
                            <img src={mobileHomapgae} alt="hero" className="mw-100 parallax" />
                        </div>
                    </div>
                </div>
            </div>
            <Records />
            {/* <Feature /> */}
            <div className="container position-relative w-100 center-content pb-md-8 pb-75" id="section-testimonials" name="section-testimonials">
                <div className="max-w-f-850 text-center position-relative group-center-top-and-bottom">
                    <div className="text-decoration-none">
                        <h4 className="text-fluoro-green-500 fw-normal extra-large">Unlock the power of data-driven decision making with our cutting-edge AI technology, designed specifically for football clubs seeking a competitive edge.</h4>
                        <p className="fw-semi-bold mt-5 medium-large">SportsKinetic.AI</p>
                    </div>
                </div>
            </div>
            <GetInTouch />
            <OurTeam />
        </div>
    )
}

export default LoggedOutHome
