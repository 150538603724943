import { authHeader } from "../helpers/authHeader";



export const apiService = {
    apiPostForm ,
    apiDelete ,
    apiGet,
};


function apiPostForm(url,formData) {

  const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: formData
  };

  return fetch(url, requestOptions)
    .then(res =>
      {
        if(res.status!==200 && res.status!==201)
         {
            throw new Error(res.status)
         } else {
           return res.json()
         }
      }
    )
    .then(result => {
        return result;
    });
}

function apiDelete(url) {

  const requestOptions = {
      method: 'DELETE',
      headers: authHeader(),
  };

  return fetch(url, requestOptions)
    .then(res =>
    {
      if(res.status!==200 && res.status!==201)
       {
          throw new Error(res.status)
       } else {
         return res.json()
       }
      }
    )
    .then(result => {
        return result;
    });
}

function apiGet(url) {

  const requestOptions = {
      method: 'GET',
      headers: authHeader(),
  };

  return fetch(url, requestOptions)
    .then(res =>
    {
      if(res.status!==200 && res.status!==201)
       {
          throw new Error(res.status)
       } else {
         return res.json()
       }
      }
    )
    .then(result => {
        return result;
    });
}
