import React from 'react';
import Chairman from '../../images/b2312d91a39d9f1c332e3b5253eeebbe.jfif';
import CTO from '../../images/c991e4f457ade3973b28f79ed4d9b032.jfif';
import CEO from '../../images/77a913140bf65abf45d5bac6f1ba8d94.png';
import Lead from '../../images/f89f1b4fa97bcc62fc5b685c833d8ec4.jfif';

const OurTeam = () => {
    return (
        <div className="container position-relative w-100 center-content">
            <div className='max-w-f-1400 w-100'>
                <h2 className="fw-extra-bold mb-md-5 mb-3">Meet our team</h2>
                <div className='row row-cols-md-4 row-cols-2 gx-md-4 gx-2 gy-4'>
                    <div>
                        <img src={CEO} alt='Chairman' className='w-100 rounded-2' />
                        <p className='mb-0 fw-normal mt-3'>Sarah Bardsley</p>
                        <p className='text-neutral-gray-700'>CEO</p>
                    </div>
                    <div>
                        <img src={CTO} alt='Chairman' className='w-100 rounded-2' />
                        <p className='mb-0 fw-normal mt-3'>Leon Harris</p>
                        <p className='text-neutral-gray-700'>CTO</p>
                    </div>
                    <div>
                        <img src={Lead} alt='Chairman' className='w-100 rounded-2' />
                        <p className='mb-0 fw-normal mt-3'>Andrew Azariah</p>
                        <p className='text-neutral-gray-700'>Lead Developer</p>
                    </div>
                    <div>
                        <img src={Chairman} alt='Chairman' className='w-100 rounded-2' />
                        <p className='mb-0 fw-normal mt-3'>David Hewitson</p>
                        <p className='text-neutral-gray-700'>Chairman</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurTeam