import React from 'react'

const Records = () => {
    return (
        <div className="container position-relative w-100 center-content w-100 pb-md-145 pb-75" id="section-sales" name="section-sales">
            <div className="max-w-f-1400 w-100">
                <div>
                    <p className="display-6 fw-extra-bold mb-0">Sports Stats powered by AI - one of the worlds most intelligent technologies applied to football.
                    </p>
                </div>
                <div className="row row-cols-2 row-cols-xl-4 gx-25 gy-5 mt-3">
                    <div className="col d-flex">
                        <div className="py-md-5  pe-md-5 py-0 pe-0">
                            <p className="display-6 extra-large fw-extra-bold text-fluoro-green-500 mb-2 mb-md-3"><i className="fa-regular fa-microchip-ai fio-fs-4"></i>
                            </p>
                            <p className="text-fluoro-green-500 extra-large fw-semi-bold mb-1">Advanced AI Technology</p>
                            <p className="fw-normal text-white">Our technology utilises advanced AI technology to analyse and interpret sports news and data on a massive scale, delivering tailored insights for scouting, player performance, and opposition analysis.
                            </p>
                        </div>
                    </div>
                    <div className="col d-flex">
                        <div className="py-md-5  pe-md-5 py-0 pe-0">
                            <p className="display-6 extra-large fw-extra-bold text-fluoro-green-500 mb-2 mb-md-3"><i className="fa-regular fa-radar fio-fs-4"></i></p>
                            <p className="text-fluoro-green-500 extra-large fw-semi-bold mb-1">Tailored Insights</p>
                            <p className="fw-normal text-white">The equivalent of 10 million search results daily, distilling crucial information to provide managers and coaching teams with valuable data and customised insights.
                            </p>
                        </div>
                    </div>
                    <div className="col d-flex">
                        <div className="py-md-5  pe-md-5 py-0 pe-0">
                            <p className="display-6 extra-large fw-extra-bold text-fluoro-green-500 mb-2 mb-md-3"><i className="fa-regular fa-diagram-sankey fio-fs-4"></i></p>
                            <p className="text-fluoro-green-500 extra-large fw-semi-bold mb-1">Contextual Analysis</p>
                            <p className="fw-normal text-white">Precisely interprets information in various languages and cross-references it with billions of data points, providing reliable and contextually rich insights.
                            </p>
                        </div>
                    </div>
                    <div className="col d-flex">
                        <div className="py-md-5  pe-md-5 py-0 pe-0">
                            <p className="display-6 extra-large fw-extra-bold text-fluoro-green-500 mb-2 mb-md-3"><i className="fa-regular fa-gears fio-fs-4"></i></p>
                            <p className="text-fluoro-green-500 extra-large fw-semi-bold mb-1">Data that empowers</p>
                            <p className="fw-normal text-white">Streamlines the analysis process, saving time for analysts and empowering clubs with essential data to make well-informed decisions and enhance their strategic approach.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Records
