import NewsImage1 from '../images/1-resize.png';
import NewsImage2 from '../images/2-resize.png';
import NewsImage3 from '../images/3-resize.png';
import NewsImage4 from '../images/4-resize.png';
import NewsImage5 from '../images/5-resize.png';
import ManCity from '../images/ca7f946c87fe681c4538959f18bf72db.png';

export default function FeaturedStoryCard({ props, data, onUpgradeClick }) {
   
    return (
        <>
            <div className="d-grid-md   fixed-1-row">
                <div className='d-none d-md-flex justify-content-end'>
                    <div className="text-decoration-none cursor-pointer text-fluoro-green-500 small flex-0 " onClick={onUpgradeClick}>More AI insights<i className="ms-3 fa-light fa-arrow-right small"></i></div>
                </div>
                <div className="d-flex h-100  bg-dark-mode-300 rounded-2  position-relative b text-decoration-none p-3 w-100">
                    <div className="d-flex flex-row h-100 w-100 justify-content-between">
                        <div className="d-flex flex-column h-100 justify-content-between">
                            <div>
                                <div className="d-flex align-items-center justify-content-start mb-3">
                                    <div className="d-flex align-items-center justify-content-start me-3">
                                        <img className="object-fit-cover w-px-68 h-px-68 rounded-2 flex-none" src={ManCity} alt="icon" />
                                    </div>
                                    <div>
                                        <p className="mb-0">Manchester City</p>
                                        <p className=" text-neutral-gray-700 mb-0 small">Premier League season 23/24 champions</p>
                                    </div>
                                </div>
                                <div className="d-grid grid-2-cols grid-gap-1 mb-3">
                                    <div className='ratio ratio-4x3'>
                                        <img src={NewsImage1} alt="NewsImage1" className='w-100 h-100 object-fit-cover rounded-1' />
                                    </div>

                                    <div className="d-grid grid-2-cols grid-gap-1">
                                        <div className='ratio ratio-4x3'>
                                            <img src={NewsImage2} alt="NewsImage2" className='w-100 h-100 object-fit-cover rounded-1' />
                                        </div>
                                        <div className='ratio ratio-4x3'>
                                            <img src={NewsImage3} alt="NewsImage3" className='w-100 h-100 object-fit-cover rounded-1' />
                                        </div>
                                        <div className='ratio ratio-4x3'>
                                            <img src={NewsImage4} alt="NewsImage4" className='w-100 h-100 object-fit-cover rounded-1' />
                                        </div>
                                        <div className='ratio ratio-4x3'>
                                            <img src={NewsImage5} alt="NewsImage5" className='w-100 h-100 object-fit-cover rounded-1' />
                                        </div>
                                    </div>
                                </div>
                                <p className=" text-neutral-gray-500 large mb-3 small">{data || "Tracking the stats behind Man City's continued run of form leading to their Premier League title. Making this the 4th in a row for the Manchester side."}</p>
                            </div>
                            <div>
                                <div  onClick={onUpgradeClick} className="btn btn-lg bg-fluoro-green-500 text-decoration-none small flex-0  fw-semi-bold  rounded-2">Go further on this story<i className="ms-3 fa-regular fa-arrow-right small"></i></div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
           
        </>
    );
}
