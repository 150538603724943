import React, { useState } from 'react'
import UpgradeModal from '../templates/UpgradeModal';
import { Modal } from 'react-bootstrap';
import Breadcrumb from '../templates/Breadcrumb';

const PerfectPlayer = () => {

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const breadcrumbItems = [
        { label: 'Back to home', to: '/' }
    ];
    return (
        <div>
            <Breadcrumb items={breadcrumbItems} back={true}/>
            <div className='d-flex flex-column align-items-md-center'>
                <div className='d-flex flex-column justify-content-md-center justify-content-end vh-80 max-w-f-600'>
                    <h4 className='mb-md-5 mb-3 fw-extra-bold'>Find your perfect player</h4>
                    <div className="d-flex overflow-scroll overflow-md-visible d-grid-md  grid-gap-3 grid-md-2-cols mb-8 pb-2 pb-md-0 position-relative" >
                        <div className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                            <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Strikers</strong> with <strong className=" text-neutral-gray-500">low injury metrics</strong> who have <strong className=" text-neutral-gray-500">scored in every game</strong> this season.</p>
                            <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                        </div>
                        <div className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                            <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Defenders</strong> with the <strong className=" text-neutral-gray-500"> most clean sheets</strong> providing <strong className=" text-neutral-gray-500">solid backline support.</strong></p>
                            <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                        </div>
                        <div className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                            <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Midfielders</strong> with <strong className=" text-neutral-gray-500">exceptional passing accuracy</strong> who <strong className=" text-neutral-gray-500">control the game's tempo.</strong></p>
                            <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                        </div>
                        <div className="d-flex flex-none flex-md-auto me-1half me-md-0 w-75 cursor-pointer w-md-100 bg-dark-mode-300 rounded-2  position-relative text-decoration-none p-3 translate-icon-on-hover">
                            <p className="mb-0 small text-neutral-gray-700"><strong className=" text-neutral-gray-500">Goalkeepers</strong> with the <strong className=" text-neutral-gray-500">highest save percentage</strong> demonstrating outstanding reflexes.</p>
                            <div className="ms-4 d-flex flex-column justify-content-end"><i className="icon text-neutral-gray-800 fa-solid fa-arrow-right small"></i></div>
                        </div>
                    </div>
                    <div>
                        <form>
                            <div className="btn-group focus-dark w-100">
                                <div className="btn btn-link border-1 border-neutral-gray-900 rounded-pill rounded-top-end-0 border-end-0 rounded-bottom-end-0 bg-dark-mode-50  small position-relative center-content"><div className='bg-fluoro-green-500 rounded-circle badge mb-0'><i className="fa-regular  fa-sparkles "></i></div></div>
                                <input type="text" onClick={handleShowModal} className="py-25 text-white bg-dark-mode-50 border-1 border-start-0 border-end-0 border-neutral-gray-900 rounded-pill form-control form-control-lg rounded-top-start-0 ro rounded-top-end-0 rounded-bottom-start-0 rounded-bottom-end-0" placeholder="Find your perfect player..." />
                                <button type="submit" className="btn btn-link border-1 border-neutral-gray-900 rounded-pill rounded-top-start-0 rounded-bottom-start-0 border-start-0 bg-dark-mode-50  small position-relative"><i className="fa fa-arrow-right text-fluoro-green-400"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Modal size="lg" centered show={showModal} onHide={handleCloseModal}>
                <Modal.Body className='bg-dark-mode-50 rounded-2 position-relative'>
                    <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-900 disc-md-45 btn-close mt-n3 me-md-n35 me-n3"
                        onClick={handleCloseModal} ></button>
                    <div className='p-md-3'>
                        <UpgradeModal />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PerfectPlayer