import InsightCard from '../templates/InsightCard';
import { useState } from 'react';
import { Modal, Carousel } from "react-bootstrap";
export default function AIInsights({ props, data, onUpgradeClick }) {

    const [carouselIndex, setCarouselIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const handleSelect = (selectedIndex) => {
        setCarouselIndex(selectedIndex);
        handleShowModal();
    };


    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    return (
        <div>
            <Modal size="lg" centered show={showModal} onHide={handleCloseModal}>
                <Modal.Body className='bg-dark-mode-50 rounded-2 position-relative'>
                    <button type="button" className="position-absolute z-index-1 end-0 top-0 disc-4 bg-neutral-gray-900 disc-md-45 btn-close mt-n3 me-md-n35 me-n3"
                        onClick={handleCloseModal} ></button>
                    <div className='p-md-3'>
                        <Carousel activeIndex={carouselIndex} onSelect={handleSelect}>
                            {
                                data.slice(1, 7).map(function (insight, i) {
                                    return <Carousel.Item
                                        key={i}
                                    >
                                        <div className='my-55 mx-65'>
                                            <p>{insight}</p>
                                        </div>
                                    </Carousel.Item>


                                })
                            }
                        </Carousel>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="d-grid-md grid-2-cols grid-gap-3 fixed-1-and-5-row h-100">
                <div className="column-span-2 mb-3 mb-md-0">
                    <div className="d-flex justify-content-between">
                        <p className="large d-md-block d-none fw-semi-bold mb-2"><i className="fa-solid fa-sparkles me-2 "></i>AI insights</p>
                        <p className="small d-md-none d-block fw-semi-bold mb-2"><i className="fa-solid fa-sparkles me-2 "></i>AI insights</p>
                        <a href="/" className="d-md-none text-decoration-none small flex-0"><i className="ms-3 fa-light fa-arrow-right small"></i></a>
                    </div>
                    <p className="mb-0">Extracting the best of the worlds footballing news so you don’t have to.</p>
                </div>
                {
                    data.slice(1, 7).map(function (object, i) {
                        return <div onClick={() => handleSelect(i)} key={i}>
                            <InsightCard
                                index={i}
                                insights={object} />
                        </div>
                    })
                }



                <span onClick={onUpgradeClick} className="btn btn-lg text-decoration-none d-none column-span-2 d-md-flex justify-content-center align-items-center w-100 bg-fluoro-green-500  fw-semi-bold rounded-2  position-relative text-decoration-none py-2 small ">Extracted insights<i className="ms-3 fa-regular fa-arrow-right small "></i></span>
            </div>
        </div>
    );
}
